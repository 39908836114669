import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import {getFormattedFullDate, getFormattedFullLocation, getTimeZoneId} from '@wix/wix-events-commons-statics'
import {ControllerParams, CreateControllerFn} from 'yoshi-flow-editor-runtime'
import {bindActionCreators} from 'redux'
import {getEvent} from './actions/event'
import {getSchedule} from './actions/schedule'
import {createApi} from './api/api'
import {rootReducer, State} from './reducers'
import {getEventDetailsUrl} from './services/location'
import {getDays, getPlaces, getTags} from './services/schedule'
import {EventScheduleProps} from './Widget'
import {tagClicked} from './actions/selected-tags'

const createController: CreateControllerFn = async (controllerParams: ControllerParams) => {
  return {
    async pageReady() {
      const api = createApi(controllerParams.flowAPI)
      const store = configureStore({
        reducer: rootReducer,
        middleware: [...getDefaultMiddleware({thunk: {extraArgument: {api, controllerParams}}})],
      })

      await store.dispatch(getEvent())
      await store.dispatch(getSchedule())

      const actions = bindActionCreators({tagClicked}, store.dispatch)
      const props = {...(await getProps(store.getState(), controllerParams)), ...actions}
      controllerParams.flowAPI.controllerConfig.setProps(props)

      store.subscribe(async () => {
        const newProps = await getProps(store.getState(), controllerParams)
        controllerParams.flowAPI.controllerConfig.setProps(newProps)
      })
    },
  }
}

const getProps = async (state: State, controllerParams: ControllerParams): Promise<EventScheduleProps> => {
  const {event, schedule} = state

  const eventDetailsUrl = await getEventDetailsUrl(controllerParams, event)
  const locale = controllerParams.controllerConfig.wixCodeApi.site.regionalSettings

  const selectedTags: string[] = state.selectedTags

  return {
    formattedEventDateAndLocation: `${getFormattedFullDate(event, locale, false)} ${getFormattedFullLocation(event)}`,
    eventTitle: event.title,
    eventDetailsUrl,
    places: getPlaces(schedule),
    tags: getTags(schedule, selectedTags),
    days: getDays(schedule, locale, getTimeZoneId(event), selectedTags),
  }
}

export default createController
