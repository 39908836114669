import {Event} from '@wix/ambassador-wix-events-web/types'
import {EVENTS_APP_ID} from '@wix/wix-events-commons-statics'
import {ControllerParams} from 'yoshi-flow-editor-runtime'

export const getSlug = (controllerParams: ControllerParams) => {
  if (controllerParams.flowAPI.inEditor) {
    return null
  }
  return controllerParams.controllerConfig.wixCodeApi.location.path[1]
}

export const getEventDetailsUrl = async (controllerParams: ControllerParams, event: Event) => {
  const url = await controllerParams.controllerConfig.wixCodeApi.site.getSectionUrl({
    sectionId: 'events',
    appDefinitionId: EVENTS_APP_ID,
  })
  return url.url + `/${event?.slug}`
}
