import {ListScheduleItemsResponse} from '@wix/ambassador-wix-events-agenda/types'
import {getDurationInMinutes, getShortDate, getTime, moment, unique} from '@wix/wix-events-commons-statics'
import {ScheduleDays, ScheduleItem} from '../types'

export const getDays = (
  schedule: ListScheduleItemsResponse,
  locale: string,
  timeZoneId: string,
  selectedTags: string[],
): ScheduleDays => {
  return schedule.items.reduce((acc, item) => {
    if (!selectedTags.length || item.tags.find(tag => selectedTags.includes(tag))) {
      const key = moment.tz(item.timeSlot.start, timeZoneId).format('YYYYMMDD')
      const scheduleItem: ScheduleItem = {
        formattedDuration: moment
          .duration(getDurationInMinutes(moment(item.timeSlot.start), moment(item.timeSlot.end)), 'minutes')
          .humanize(),
        formattedTimeSlot: `${getTime(item.timeSlot.start, timeZoneId, locale)} - ${getTime(
          item.timeSlot.end,
          timeZoneId,
          locale,
        )}`,
        name: item.name,
        description: item.description,
        tags: item.tags?.map(tag => ({
          checked: false,
          title: tag,
          value: tag,
        })),
        location: item.stageName,
      }
      acc[key] = {
        title: getShortDate(item.timeSlot.start, timeZoneId, locale),
        items: [...(acc[key]?.items ?? []), scheduleItem],
      }
    }
    return acc
  }, {} as ScheduleDays)
}

export interface ScheduleTag {
  checked: boolean
  title: string
  value: string
}

export const getTags = (schedule: ListScheduleItemsResponse, selectedTags: string[]): ScheduleTag[] =>
  schedule.items
    .flatMap(item => item.tags)
    .filter(unique)
    .map(tag => ({
      checked: selectedTags.includes(tag),
      title: tag,
      value: tag,
    })) || []

export interface SchedulePlace {
  id: string
  value: string
  isSelectable: boolean
}

export const getPlaces = (schedule: ListScheduleItemsResponse): SchedulePlace[] =>
  schedule.items
    .map(item => item.stageName)
    .filter(unique)
    .map(place => ({
      id: place,
      value: place,
      isSelectable: true,
    })) || []
