import {createReducer} from '@reduxjs/toolkit'
import {ListScheduleItemsResponse} from '@wix/ambassador-wix-events-agenda/types'
import {getSchedule} from '../actions/schedule'

const initialState: ListScheduleItemsResponse = {items: []}

export const schedule = createReducer(initialState, builder => {
  builder.addCase(getSchedule.fulfilled, (state, action) => {
    return action.payload
  })
})
