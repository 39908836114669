import {createAsyncThunk} from '@reduxjs/toolkit'
import {ListScheduleItemsResponse} from '@wix/ambassador-wix-events-agenda/types'
import {ThunkConfig} from './interfaces'

export const getSchedule = createAsyncThunk<ListScheduleItemsResponse, undefined, ThunkConfig>(
  'GET_SCHEDULE',
  (_, {extra, getState}) => {
    const eventId = getState().event.id
    return extra.api.getSchedule(eventId)
  },
)
