import {createAsyncThunk} from '@reduxjs/toolkit'
import {Event} from '@wix/ambassador-wix-events-web/types'
import {getSlug} from '../services/location'
import {ThunkConfig} from './interfaces'

export const getEvent = createAsyncThunk<Event, undefined, ThunkConfig>(
  'GET_EVENT',
  (args, {extra: {api, controllerParams}}) => {
    const slug = getSlug(controllerParams)
    return slug ? api.getEventBySlug(slug) : api.getEvent()
  },
)
