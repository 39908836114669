import {createReducer} from '@reduxjs/toolkit'
import {tagClicked} from '../actions/selected-tags'

const initialState: string[] = []

export const selectedTags = createReducer(initialState, builder => {
  builder.addCase(tagClicked, (state, action) => {
    if (state.includes(action.payload)) {
      return state.filter(tag => tag !== action.payload)
    } else {
      return [...state, action.payload]
    }
  })
})
