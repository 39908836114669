import {StateFilter, WixEventsAgenda} from '@wix/ambassador-wix-events-agenda/http'
import {WixEventsWeb} from '@wix/ambassador-wix-events-web/http'
import {SCHEDULE_API_URL, EVENTS_WEB_API_URL} from '@wix/wix-events-commons-statics'
import {ControllerFlowAPI} from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript'

export const createApi = (flowAPI: ControllerFlowAPI) => {
  let authorization = flowAPI.controllerConfig.appParams.instance

  flowAPI.controllerConfig.wixCodeApi.site.onInstanceChanged(({instance}) => {
    authorization = instance
  })

  const wixEvents = WixEventsWeb(EVENTS_WEB_API_URL)
  const agenda = WixEventsAgenda(SCHEDULE_API_URL)

  return {
    getEventBySlug: async (slug: string) => (await wixEvents.EventManagement()({authorization}).get({slug})).event,
    getEvent: async () => (await wixEvents.EventManagement()({authorization}).list({limit: 1, offset: 0})).events[0],
    getSchedule: (eventId: string) =>
      agenda
        .ScheduleManagement()({authorization})
        .list({eventId, state: [StateFilter.PUBLISHED], limit: 100, offset: 0}),
  }
}

export type Api = ReturnType<typeof createApi>
